let NODE_ENV = "demo";

let Front_URL = "";
let rpcURL = "";
let decimalvalues = 1000000000000000000;
let toFixed = 4;
let networkVersion = "";
let bscurl = "";
let adminAddress = "";
let mlmcontract = "";
let usdtcontract = "";
let chainId = "";
let Server_URL = "";
let projectId = "";
const ownerAddr = "";

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined
};

if (NODE_ENV == "production") {
  Front_URL = "https://www.zeus.eu.com/";
  networkVersion = 56; //56
  rpcURL = "https://bsc-dataseed.binance.org/";
  bscurl = "https://bscscan.com/";
  adminAddress = "0xFF87Deaf12627DAe49F27228075a085E5098750F";
  mlmcontract = "0x7e07401f537582ac40fa9f5Ae8Bc93Ef51248700";
  usdtcontract = "0x55d398326f99059fF775485246999027B3197955";
  chainId = 56; //56
  projectId = "cb89ebb21cdccb2e1b591e189e27706a"; //wallet connect
} else if (NODE_ENV == "demo") {
  Front_URL = "https://zeus-bot-demo.pages.dev/";
  networkVersion = 80001;
  rpcURL = "https://bsc-testnet.publicnode.com/";
  bscurl = "https://testnet.bscscan.com/";
  adminAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F";
  mlmcontract = "0xf692EeaAe4F0BFf7c745B3c1c95E7d65112EdC86";
  usdtcontract = "0x93025e4EF1eb317ae689712E23E363EABd46CdBf";
  chainId = 80001; //97
  projectId = "cb89ebb21cdccb2e1b591e189e27706a"; //wallet connect
} else {
  Front_URL = "http://localhost:3000/";
  Server_URL = "http://localhost:2053/";
  networkVersion = 80001;
  rpcURL = "https://bsc-testnet.publicnode.com";
  bscurl = "https://testnet.bscscan.com/";
  adminAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F";
  mlmcontract = "0xf692EeaAe4F0BFf7c745B3c1c95E7d65112EdC86";//0xd53d0B61c12eBa8bf354355951A924039A8e6b03
  usdtcontract = "0x93025e4EF1eb317ae689712E23E363EABd46CdBf";
  chainId = 80001; //97
  projectId = "cb89ebb21cdccb2e1b591e189e27706a"; //wallet connect
}

let key = {
  Front_URL: Front_URL,
  Server_URL: Server_URL,
  decimalvalues: decimalvalues,
  toFixed: toFixed,
  rpcURL: rpcURL,
  networkVersion: networkVersion,
  toasterOption: toasterOption,
  adminAddress: adminAddress,
  mlmcontract: mlmcontract,
  usdtcontract: usdtcontract,
  bscurl: bscurl,
  chainId: chainId,
  projectId: projectId
};

export default key;
