import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import copy from "copy-to-clipboard";
import zeus from "../assets/images/zeus.svg";
import Web3 from "web3";
import { getCurAddr, updateregister } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import mlmabi from "../ABI/mlmABI";
import usdtabi from "../ABI/usdtABI";
import config from "../config/config";
import { type WalletClient, useWalletClient } from "wagmi";
import AOS from "aos";
import "aos/dist/aos.css";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  var { id } = useParams();
  console.log(id, "ididididid");
  const [data, setdata] = useState({});
  const [referaladdress, setreferaladdress] = useState(id);
  const [progress, setprogress] = useState("false");
  const [investamt, setinvestamt] = useState("20000000000000000000");
  const [hadalready, sethadalready] = useState("no");
  const [isTokenjoin, setisTokenjoin] = useState(false);

  let chainid = config.networkVersion;
  const { data: walletClient } = useWalletClient({ chainid });

  useEffect(() => {
    AOS.init();
    checkreferal();
    getdetails();
  }, [walletClient]);

  const referralchange = (e) => {
    setreferaladdress(e.target.value);
  };

  async function checkreferal() {
    if (id && id != null && id != undefined && id != "") {
      setreferaladdress(id);
    }
  }

  async function getdetails() {
    let datas = await getCurAddr();
    if (
      datas &&
      datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" &&
      localStorage.getItem("account")
    ) {
      setdata(datas);
      if (datas.userdetails && datas.userdetails.isExist) {
        window.location.href = "/dashboard";
      } else {
        getreferral(datas);
      }
    }
  }

  async function getreferral(_data) {
    // let inputdata = {
    //   "useraddress" : _data.address,
    // }
    //let rest = await checkreferal(inputdata);
    if (_data && _data.userdetails && _data.userdetails.parent) {
      // setreferaladdress(_data.userdetails.parent);
      sethadalready("yes");
    } else {
      sethadalready("no");
    }
  }

  async function empty() {}

  async function submit() {
    try {
      setprogress("true");
      let datas = await getCurAddr();
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "" &&
        localStorage.getItem("account")
      ) {
        if (datas.userdetails && datas.userdetails.isExist) {
          setprogress("false");
          toastAlert("error", "Already Joined", "network");
          window.location.href = "/dashboard";
        } else {
          if (referaladdress == "" || referaladdress == undefined) {
            setprogress("false");
            toastAlert("error", "Referral id required", "network");
          }
          if (referaladdress.toLowerCase() != datas.address.toLowerCase()) {
            let web3 = new Web3(
              datas &&
              datas.provider &&
              datas.provider != null &&
              datas.provider != undefined &&
              datas.provider != ""
                ? datas.provider
                : window.ethereum
            );
            if (web3.utils.isAddress(referaladdress)) {
              if (investamt && parseFloat(investamt) > 0) {
                console.log(investamt,'investamt');
                var usdtContract = new web3.eth.Contract(
                  usdtabi,
                  config.usdtcontract
                );
                var mlmContract = new web3.eth.Contract(
                  mlmabi,
                  config.mlmcontract
                );
                var tokenbalance = await usdtContract.methods
                  .balanceOf(datas.address)
                  .call();
                let userdetails = await mlmContract.methods
                  .users(referaladdress)
                  .call();

                  console.log(userdetails,'userdetailsuserdetailsuserdetails');
                let bnbBal = await web3.eth.getBalance(datas.address);
                bnbBal = bnbBal / 1e18;
                console.log(bnbBal, "bnbBal");
                if (userdetails.isExist) {
                  console.log(
                    parseFloat(tokenbalance) >= parseFloat(investamt),
                    "acdascdacf"
                  );
                  if (parseFloat(tokenbalance) >= parseFloat(investamt)) {
                    // let joinamt = await convert(1000000000000000000000000000);
                    let joinamt = await mlmContract.methods
                      .poolInfo("0")
                      .call();
                    console.log(joinamt, "joinamt");
                    joinamt = joinamt.poolAmount;
                    console.log(joinamt, joinamt / 1e18, "afcdacf");

                    let estimateGas = await usdtContract.methods
                      .approve(config.mlmcontract, joinamt.toString())
                      .estimateGas({ from: datas.address })
                      .catch((err) => console.log(err));
                    let gasPricebusd = await web3.eth.getGasPrice();
                    console.log(estimateGas * gasPricebusd, "estimateGas");
                    let estimateGasFee = (estimateGas * gasPricebusd) / 1e18;
                    console.log(
                      estimateGasFee,
                      bnbBal,
                      estimateGasFee > bnbBal,
                      "edwfced"
                    );

                    if (estimateGasFee > bnbBal) {
                      setprogress("false");
                      toastAlert("error", "BNB Balance is Low", "network");
                      return false;
                    }

                    var allowance = await usdtContract.methods
                      .approve(config.mlmcontract, joinamt.toString())
                      .send({
                        from: datas.address
                      });
                    if (allowance) {
                      let register = await mlmContract.methods
                        .joinPlan(referaladdress)
                        .send({
                          from: datas.address
                        });
                      if (
                        register &&
                        register.status &&
                        register.transactionHash
                      ) {
                        setprogress("false");
                        toastAlert("success", "Joined Successfully", "network");
                        setTimeout(() => {
                          window.location.href = "/dashboard";
                        }, 2500);
                      } else {
                        setprogress("false");
                        toastAlert(
                          "success",
                          "Failed , try again later",
                          "network"
                        );
                      }
                    } else {
                      setprogress("false");
                      toastAlert(
                        "success",
                        "Failed , try again later",
                        "network"
                      );
                    }
                  } else {
                    setprogress("false");
                    toastAlert("error", "USDT Balance is Low", "network");
                  }
                } else {
                  setprogress("false");
                  toastAlert("error", "Referral user not exist", "network");
                }
              } else {
                setprogress("false");
                toastAlert("error", "Invalid Invest Amount", "network");
              }
            } else {
              if (
                referaladdress &&
                referaladdress != null &&
                referaladdress != undefined &&
                referaladdress != ""
              ) {
                setprogress("false");
                toastAlert("error", "Invalid Referral Link", "network");
              } else {
                setprogress("false");
                toastAlert("error", "Please Enter valid Address", "network");
              }
            }
          } else {
            setprogress("false");
            toastAlert(
              "error",
              "Your Wallet and Referral both are same",
              "network"
            );
          }
        }
      } else {
        setprogress("false");
        toastAlert("error", "Please select BSC chain network", "network");
      }
    } catch (err) {
      console.log(err, "=-===allowanceallowanceallowance");
      setprogress("false");
    }
  }

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  return (
    <div>
      {/* <ScrollToTopOnMount />  */}
      <Navbar />
      <div className="main_wrapper">
        <section className="platform joinnow">
          <div className="container">
            <div className="platform_bg">
              <h2 className="h2tag text-center mb-4">Join Now</h2>
              <div className="row">
                <div className="col-lg-7 mx-auto">
                  <div className="flx">
                    <div className="box flex-column py-5">
                      <input
                        type="text"
                        className="form-control mb-3"
                        id="refadd"
                        placeholder="Referral ID"
                        onChange={referralchange}
                        disabled={id ? true : false}
                        value={referaladdress}
                      />
                      <span style={{ fontSize: "14px", marginBottom: "20px",wordBreak:"break-word" }}>
                        Default ID : {config.adminAddress}{" "}
                        <i
                          class="fa-regular fa-copy cursor"
                          onClick={() => {
                            copy(config.adminAddress);
                            toastAlert("success", "Copied Successfully");
                          }}
                        ></i>
                      </span>
                      <button
                        class="primary_btn w-auto"
                        onClick={
                          progress == "false" ? () => submit() : () => empty()
                        }
                      >
                        <span class="button__icon-wrapper">
                          <svg
                            class="button__icon-svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <path
                              d="M0.886663 8.5533L8.28809 1.15188M8.28809 1.15188H2.73702M8.28809 1.15188V6.70295"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          <svg
                            class="button__icon-svg  button__icon-svg--copy"
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            fill="none"
                            viewBox="0 0 14 15"
                          >
                            <path
                              fill="currentColor"
                              d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                            ></path>
                          </svg>
                        </span>
                        {progress == "false"
                          ? "Join Now"
                          : "Processing..Please Wait.."}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
