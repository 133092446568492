import { useEffect, useState } from "react"
import Navbar from "../components/Navbar"
import { Wheel } from "react-custom-roulette"
import Web3 from "web3"
import { useSelector } from "react-redux"
import { getCurAddr } from "../action/Apicontroller"
import store from "../store"
import MLMABI from '../ABI/mlmABI.json'
import config from '../config/config'
import reminder from "../assets/images/reminder.svg";
import spincount from "../assets/images/spincount.svg";
import spinincome from "../assets/images/spinincome.svg";
import { useWalletClient } from "wagmi"
import { toastAlert } from "../helper/toastAlert"


const data = [
    { id: 0, option: 0, style: { backgroundColor: 'green', textColor: 'white' } },
    { id: 1, option: 5, style: { backgroundColor: '#02243d', textColor: 'white' } },
    { id: 2, option: 10, style: { backgroundColor: 'black', textColor: 'white' } },
    { id: 3, option: 15, style: { backgroundColor: '#02243d', textColor: 'white' } },
    { id: 4, option: 20, style: { backgroundColor: 'black', textColor: 'white' } },
    { id: 5, option: 25, style: { backgroundColor: '#02243d', textColor: 'white' } },
    { id: 6, option: 30, style: { backgroundColor: 'black', textColor: 'white' } },
    { id: 7, option: 35, style: { backgroundColor: '#02243d', textColor: 'white' } },
    { id: 8, option: 40, style: { backgroundColor: 'black', textColor: 'white' } },
    { id: 9, option: 45, style: { backgroundColor: '# 02243d', textColor: 'white' } },
    { id: 10, option: 50, style: { backgroundColor: 'black', textColor: 'white' } },

]

// const data = [
//     { id: 0, option: 0, style: { backgroundColor: 'green', textColor: 'white' } },
//     { id: 1, option: 15, style: { backgroundColor: '#02243d', textColor: 'white' } },
//     { id: 2, option: 30, style: { backgroundColor: 'black', textColor: 'white' } },
//     { id: 3, option: 5, style: { backgroundColor: '#02243d', textColor: 'white' } },
//     { id: 4, option: 40, style: { backgroundColor: 'black', textColor: 'white' } },
//     { id: 5, option: 50, style: { backgroundColor: '#02243d', textColor: 'white' } },
//     { id: 6, option: 10, style: { backgroundColor: 'black', textColor: 'white' } },
//     { id: 7, option: 35, style: { backgroundColor: '#02243d', textColor: 'white' } },
//     { id: 8, option: 20, style: { backgroundColor: 'black', textColor: 'white' } },
//     { id: 9, option: 45, style: { backgroundColor: '# 02243d', textColor: 'white' } },
//     { id: 10, option: 25, style: { backgroundColor: 'black', textColor: 'white' } },

// ]


const SpinnerPage = () => {

    let chainid = config.networkVersion;
    const { data: walletClient } = useWalletClient({ chainid });
    const [mustSpin, setMustSpin] = useState(false)
    const [priceNumber, setPriceNumber] = useState(0)
    const [SpinCount, setSpinCount] = useState(0)
    const [spinIncome, setSpinIncome] = useState(0)
    const [RemainingSpin, setRemainingSpin] = useState(0)
    const [btnDissaple, setbtnDisaple] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getdetails();
    }, [walletClient]);

    async function getdetails() {
        let datas = await getCurAddr();
        if (
            datas &&
            datas.address &&
            datas.address != undefined &&
            datas.address != null &&
            datas.address != "" &&
            localStorage.getItem("account")
        ) {

            if (datas.userdetails && datas.userdetails.isExist) {
                console.log("Hi");
            } else {
                window.location.href = "/join-now";
            }
        } else {
            //window.location.href = "/";
        }
    }



    // var reduxVal = store.getState();
    // console.log(reduxVal, 'reduxVareduxVareduxVareduxVareduxVa');
    const getLoader = async () => {

        const UserAddress = await getCurAddr()
        console.log(UserAddress, 'UserAddressUserAddress');

        try {

            var transfort =
                UserAddress &&
                    UserAddress.provider &&
                    UserAddress.provider.key
                    ? UserAddress.provider
                    : {};
            console.log(transfort, "===========");


            if (
                transfort &&
                transfort != null &&
                transfort != undefined &&
                transfort != "" &&
                Object.keys(transfort).length != 0
            ) {

                setbtnDisaple(true)
                const web3 = await new Web3(transfort)
                const mlmContract = await new web3.eth.Contract(MLMABI, config.mlmcontract)
                console.log(mlmContract, 'mlmContractmlmContractmlmContract');
                const RemaimngSpin = await mlmContract.methods.userSpinCount(UserAddress.address).call()


                const SpinIncome = await mlmContract.methods.spinIncome(UserAddress.address).call()

                console.log(SpinIncome, 'SpinIncomeSpinIncomeSpinIncome');
                const SpinCount = await mlmContract.methods.viewUserRefaral(UserAddress.address).call()
                console.log(SpinCount, "userSpinCountuserSpinCountuserSpinCount");
                console.log(SpinCount.length - RemaimngSpin, RemaimngSpin, 'viewUserRefaralviewUserRefaral-RemaimngSpinRemaimngSpinRemaimngSpin');
                setRemainingSpin(RemaimngSpin)
                setSpinIncome(SpinIncome)
                setSpinCount(SpinCount.length - RemaimngSpin)
                setbtnDisaple(false)
            } else {
                setbtnDisaple(true)
                toastAlert('error', 'Please Connect Wallet')
            }
        } catch (error) {
            console.log(error, 'errorerror');
        }

    }

    useEffect(() => {
        if (walletClient) {

            getLoader()
        }

    }, [walletClient])

    const SpinHandler = async () => {

        const UserAddress = await getCurAddr()
        console.log(UserAddress, 'UserAddressUserAddress');
        setLoading(true)
        try {

            var transfort =
                UserAddress &&
                    UserAddress.provider &&
                    UserAddress.provider.key
                    ? UserAddress.provider
                    : {};
            console.log(transfort, "===========");


            if (
                transfort &&
                transfort != null &&
                transfort != undefined &&
                transfort != "" &&
                Object.keys(transfort).length != 0
            ) {

                const web3 = await new Web3(transfort)
                const mlmContract = await new web3.eth.Contract(MLMABI, config.mlmcontract)
                console.log(mlmContract, 'mlmContractmlmContractmlmContract');
                await mlmContract.methods.spinWheel().send({
                    from: UserAddress.address,
                    gas: 300000
                })
                    .on('transactionHash', function (hash) {
                        console.log('Transaction Hash:', hash);
                    })
                    .on('receipt', async function (receipt) {
                        console.log('Receipt:', receipt);
                        if (receipt.events && receipt.events.spinWheelEmit) {
                            const emittedValue = receipt.events.spinWheelEmit.returnValues._id;
                            console.log('Emitted Value:', emittedValue);
                            setMustSpin(true)
                            
                            setPriceNumber(emittedValue)
                            setTimeout(() => {
                                toastAlert("success", "Done")
                                setLoading(false)
                            }, 2000)
                            setTimeout(()=>{
                                window.location.reload()
                            },5000)
                            
                        }
                    })
                    .on('error', function (error) {
                        console.error('Error:', error);
                        setLoading(false)
                    });
                // .then((resh) => {
                //     let txHash = resh.transactionHash;
                //     console.log(txHash, 'txHash');
                //     web3.eth.getTransactionReceipt(txHash, (error, receipt) => {
                //         if (error != null) {
                //             console.error("Error fetching transaction receipt:", error);
                //             return;
                //         }
                //         console.log(receipt, 'receiptreceiptreceiptreceipt');
                //         const transferLogs = receipt?.logs?.filter(
                //             (log) => log.address.toLowerCase() === config.mlmcontract.toLocaleLowerCase()
                //         );
                //         console.log(transferLogs, 'transferLogs');
                //         // transferLogs.forEach(async (log, i) => {
                //         //     const eventAbi = MLMABI.find(
                //         //         (abi) => abi.type == "event" && log.topics[0] === web3.utils.sha3(abi.name +)
                //         //     )
                //         // })
                //     })
                // }).catch((err) => console.log(err))


                // if (playGame) {

                //     // await data.find(res=>{
                //     //     res.option==parseFloat(playGame)
                //     // })

                //     setMustSpin(true)
                //     setPriceNumber(2)

                // } else {

                // }


            } else {
                toastAlert('error', 'Please connect wallet')
            }
        } catch (error) {
            console.log(error);
        }


    }

    return (
        <div>
            <Navbar />
            <div className="page_box pb-5">
                <div className="dashboard">
                    <div className="container">
                        <div className="head_flx mb-4">
                            <h3 className="h3tag">SpinnerPage</h3>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-xl-3">
                                <div className="dashbox mb-4">
                                    <div className="box box_hover mb-4">
                                        <div className="img_box">
                                            <img src={spincount} class="img-fluid" alt="img" />
                                        </div>
                                        <div>
                                            <h5>
                                                {
                                                    SpinCount
                                                }
                                            </h5>
                                            <p className="mb-0">Spin Count</p>
                                        </div>
                                    </div>
                                    <div className="box box_hover mb-4">
                                        <div className="img_box">
                                            <img src={spinincome} class="img-fluid" alt="img" />
                                        </div>
                                        <div className="w-100">
                                            <div className="d-flex align-items-start justify-content-between gap-2">
                                                <h5>
                                                    {
                                                        spinIncome / 1e18
                                                    }
                                                </h5>
                                                <span>USDT</span>
                                            </div>
                                            <p className="mb-0">Spin Income</p>
                                        </div>
                                    </div>
                                    <div className="box box_hover mb-4">
                                        <div className="img_box">
                                            <img src={reminder} class="img-fluid" alt="img" />
                                        </div>
                                        <div className="w-100">
                                            <div className="d-flex align-items-start justify-content-between gap-2">
                                                <h5>
                                                    {
                                                        RemainingSpin
                                                    }
                                                </h5>
                                                <span>USDT</span>
                                            </div>
                                            <p className="mb-0">No Of Spin Remainning</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-8 col-xl-9">
                                <div className="dashbox mb-4">
                                    <div className="row">
                                        <div className="col-md-8 mx-auto" >
                                            <div className="text-center" >
                                                <h3 className="h4tag mb-3" >Spinner</h3>
                                            </div>
                                            <div className="spinner">
                                                <Wheel
                                                    mustStartSpinning={mustSpin}
                                                    prizeNumber={priceNumber}
                                                    data={data}
                                                    onStopSpinning={() => {
                                                        setMustSpin(false);
                                                    }}
                                                    outerBorderColor={"#757272"}
                                                    outerBorderWidth={15}
                                                    // innerBorderWidth={2}
                                                    radiusLineWidth={7}
                                                    radiusLineColor={"#757272"}
                                                    spinDuration={0.3}
                                                // pointerProps={}
                                                />
                                            </div>
                                            {
                                                RemainingSpin > 0 ? <div className="text-center mt-3">
                                                    {
                                                        loading ? <button className="primary_btn mx-auto" disabled={true} >
                                                            <span class="button__icon-wrapper">
                                                                <svg class="button__icon-svg" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path d="M0.886663 8.5533L8.28809 1.15188M8.28809 1.15188H2.73702M8.28809 1.15188V6.70295" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg><svg class="button__icon-svg  button__icon-svg--copy" xmlns="http://www.w3.org/2000/svg" width="10" fill="none" viewBox="0 0 14 15"><path fill="currentColor" d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"></path></svg></span>

                                                            Processing...</button> : <button className="primary_btn mx-auto" disabled={btnDissaple} onClick={() => SpinHandler()}>
                                                            <span class="button__icon-wrapper">
                                                                <svg class="button__icon-svg" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path d="M0.886663 8.5533L8.28809 1.15188M8.28809 1.15188H2.73702M8.28809 1.15188V6.70295" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg><svg class="button__icon-svg  button__icon-svg--copy" xmlns="http://www.w3.org/2000/svg" width="10" fill="none" viewBox="0 0 14 15"><path fill="currentColor" d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"></path></svg></span>

                                                            SPIN</button>
                                                    }

                                                </div> : <div className="text-center mt-3">You have no spin Remaining</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SpinnerPage